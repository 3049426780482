import { Link } from "gatsby"
import React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import Seo from "../../components/seo"

export default function SyRFGuide(props) {
  return (
    <Layout language="en" pathname={props.location.pathname}>
      <Seo
        title="SyRF Guide"
        lang="en"
        description="Welcome to the SyRF User Guide, created to help you use the platform"
      />
      <GradientJumbo subtitle="SyRF" title="A guide for SyRF in Portuguese" />
      <section className="container">
        <h3 className="fw-bold">
          The CAMARADES BR's SyRF guide is only available in <u>Portuguese</u>
        </h3>
        <p>
          You can check out the portuguese version{" "}
          <Link to="/guia-syrf">right here</Link>.
        </p>
        <p>
          If you are looking for the CAMARADES UK's English version you can
          click{" "}
          <a
            href="https://camaradesuk.github.io/syrf_userguide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </section>
      <Contact language="en" />
    </Layout>
  )
}
